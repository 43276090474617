import { createTheme } from '@material-ui/core'

const theme = createTheme({
  palette: {
    common: {
      white: '#FEFEFE'
    },
    primary: {
      main: '#D90368',
    },
    secondary: {
      main: '#F78703',
    },
    accent: {
      main: '#440381'
    },
    text: {
      primary: '#393E46',
      secondary: '#737B87'
    },
    divider: '#F6F4F4'
  },
  scrollReveal: {
    animation: 'slide-up',
    duration: 750
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Raleway, Roboto, Helvetica, sans-serif'
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
        textTransform: 'none',
      }
    },
    MuiTextField: {
      root: {
        borderRadius: 8
      }
    }
  }
})

export default theme