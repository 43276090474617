import React from 'react'
import Helmet from 'react-helmet'
import CssBaseline from '@material-ui/core/CssBaseline'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import theme from '../../src/theme'

const TopLayout = (props) => {
  return (
    <>
      <Helmet>
        <title>PixelSearch - Image search, simplified</title>
        <meta name='description' content='Build a custom visual search API in hours. PixelSearch is a hosted visual search API
        that can query tens of thousands of images with sub-second latency. Bring visual search to your app in record time.'/>
        <meta property="og:url" content="https://pixelsearch.io/"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="PixelSearch - Visual search, simplified"/>
        <meta property="og:description" content="Build a custom visual search API in hours. PixelSearch is a hosted visual search API that can query tens of thousands of images with sub-second latency. Bring visual search to your app in record time."/>
        <meta property="og:image" content="https://pixelsearch.io/image/og_image.jpg"/>
        <link rel="preload"
              href="https://fonts.googleapis.com/css?family=Quicksand|Raleway"
              as="font"/>
        <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Quicksand|Raleway"/>
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        {props.children}
      </ThemeProvider>
    </>
  )
}

export default TopLayout